import { NgClass, NgOptimizedImage } from '@angular/common';
import { Component, input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cg-loading',
  standalone: true,
  imports: [NgClass, NgOptimizedImage],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
  encapsulation: ViewEncapsulation.None
})
export default class LoadingComponent {
  constructor() { }
  fullScreen = input(true)
}
